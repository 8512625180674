<template>
  <div v-if="programClinician !== 'OASAS'">
    <b-button
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="flat-primary"
        class="mr-50 float-right"
        :to="{ name: 'disabled-clients' }"
        v-if="userData && isAdmin"
    >
        <feather-icon
            icon="EyeIcon"
            class="mr-50"
        />
        <span class="align-middle">Disabled Clients</span>
    </b-button>
    <h1 class="mb-1">Clients</h1>

    <b-card
      no-body
      class="mb-0 mt-2"
    >

      <div class="mx-1">
        <h4 style="font-size: 18px;" class="mb-0 pb-0 mt-2">Filters</h4>
        <!-- Table Top -->
        <b-row class="mt-1 d-flex align-items-center justify-content-start mb-1 mb-md-0" cols="1" cols-sm="12" cols-md="5" cols-lg="5">
          <b-col class="mb-25">
            <v-select
              v-model="clinicianName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              placeholder="Clinician Name"
              label="full"
              :options="optionsClinicianName"
            >
            </v-select>
          </b-col>
          <b-col class="mb-25">
            <b-form-input
              v-model="clientName"
              class="d-inline-block mr-1"
              placeholder="Client Name"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="clientCounty"
              inline
              :options="optionsClientCounty"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-50"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="clinicianRegion"
              inline
              :options="optionsClinicianRegion"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
          <b-col class="mb-25" v-if="clinicianRegion === 'NYC'">
            <b-form-select
              v-model="borough"
              inline
              :options="optionsBorough"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-25"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-select
              v-model="clientStatus"
              inline
              :options="optionsClientStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="d-inline-block mr-50"
            />
          </b-col>
          <b-col class="mb-25">
            <b-form-input
              v-model="clientID"
              class="d-inline-block mr-1"
              placeholder="Client ID"
            />
          </b-col>
          <b-col class="mb-25" v-if="adminOrManageUsers || dataSpecialist">
            <b-form-input
              v-model="clientEmail"
              class="d-inline-block mr-1"
              placeholder="Client Email"
            />
          </b-col>
          <b-col class="mb-25" v-if="adminOrManageUsers || dataSpecialist">
            <b-form-input
              v-model="clientPhoneNumber"
              class="d-inline-block mr-1"
              placeholder="Client Phone Number"
            />
          </b-col>
        </b-row>

        <b-row class="my-1">
          <b-col
            cols="12"
            md="6"
          >
            <div class="text-primary" v-if="apiLoaded">
              <b-spinner class="align-middle"></b-spinner>
              <span style="font-size: 14px; color: #000;"> results found</span>
            </div>
            <h2 v-else>{{totalRows.toLocaleString('en-US')}} <span style="font-size: 14px; color: #000;"> results found</span></h2>
          </b-col>
          
          <b-col
            cols="12"
            md="6"
            v-if="!isClinician && !isClinicianStaff"
          >
            <b-button
              variant="primary"
              :to="{ name: 'add-client' }"
              class="float-right"
            >
              <span class="text-nowrap">Add Client</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div>
        <b-table
          ref="refUserListTable"
          :items="arrayFiltradoClient"
          responsive
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :fields="isClinician || isClinicianStaff ? tableColumnsClientsClinician : tableColumnsClients"
          show-empty
          empty-text="No records found"
          empty-filtered-text="No results match"
          :per-page="perPage"
          :current-page="currentPage"
          hover
          @row-clicked="onRowSelected"
          :busy="apiLoaded"
        >
          <template #table-busy>
              <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-1">Loading...</strong>
              </div>
          </template>

          <!-- Column: dateOfBirth -->
          <template #cell(dateOfBirth)="data" sortable centered>
            <span>
              {{ data.item.dateOfBirth ? new Date(data.item.dateOfBirth).toLocaleDateString() : '' }}
            </span>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              class="p-50 ml-25"
              :to="{ name: 'edit-client', params: {id: data.item.id, destination: 'engagement'} }"
              v-b-tooltip.hover.top="'Create new engagement session'"
              v-if="!dataSpecialist"
            >
              <feather-icon icon="CalendarIcon" />
            </b-button>

            <span v-if="dataSpecialist"> - </span>
            <div v-else>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                v-if="data.item.statusClient === 'inactive'"
                class="p-50 ml-25"
                @click="BtnActivateClient(data.item.id)"
                v-b-tooltip.hover.top="'Activate'"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="p-50 ml-25"
                v-else
                @click="BtnDeactivateClient(data.item.id)"
                v-b-tooltip.hover.top="'Disable'"
              >
                <feather-icon icon="SlashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="text-center text-primary my-2" v-if="loadingInfiniteScroll">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-1">Loading...</strong>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup, BTabs, BTab, BCardText, BSpinner,
  BBadge, BDropdown, BDropdownItem, BDropdownItemButton, BPagination, BModal, VBToggle, BFormSelect, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import { VueAutosuggest } from 'vue-autosuggest'
import { mapActions, mapGetters, mapState } from "vuex"
import { db, auth, firebase } from '@/firebase'

export default {
  data() {
    return {
      searchQuery: '',
      clients: [],
      tableColumnsClients: [
        { key: 'clientID', sortable: false },
        { key: 'status', sortable: false },
        { key: 'name', sortable: false },
        { key: 'email', sortable: false },
        { key: 'dateOfBirth', sortable: true },
        { key: 'actions', label: '', sortable: false, tdClass: 'actionsCol' },
      ],
      tableColumnsClientsClinician: [
        { key: 'clientID', sortable: false },
        { key: 'status', sortable: false },
        { key: 'name', sortable: false },
        { key: 'email', sortable: false },
        { key: 'dateOfBirth', sortable: true },
        // { key: 'actions', label: '', sortable: false, tdClass: 'actionsCol' },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 30,
      pageOptions: [3, 5, 10, 25, 50],
      // xx: [],

      // filters
      clinicianName: null,
      optionsClinicianName: [],
      clientName: null,
      clientCounty: null,
      optionsClientCounty: [],
      clinicianRegion: null,
      optionsClinicianRegion: [],
      borough: null,
      optionsBorough: [
        {value: null, text: 'Any Borough'},
        {value: 'Bronx', text: 'Bronx'},
        {value: 'Brooklyn', text: 'Brooklyn'},
        {value: 'Manhattan', text: 'Manhattan'},
        {value: 'Queens', text: 'Queens'},
        {value: 'Staten Island', text: 'Staten Island'}
      ],
      clientStatus: null,
      optionsClientStatus: [
        {value: null, text: 'Any Client Status'},
        {value: 'Discharged', text: 'Discharged'},
        {value: 'Admitted', text: 'Admitted'},
        {value: 'Engaged', text: 'Engaged'},
        {value: 'Closed - Attended First Session', text: 'Closed – Attended First Session'},
        {value: 'Closed - Did not attend first session', text: 'Closed – Did not attend first session'},
        {value: 'Closed – Received information', text: 'Closed – Received information'},
        {value: 'Closed – Referred to OASAS program', text: 'Closed – Referred to OASAS Program'},
        {value: 'External Referral', text: 'External Referral'},
        {value: 'On Hold', text: 'On Hold'}
      ],
      sortBy: 'dateOfBirth',
      sortDesc: true,
      clientID: null,
      clientEmail: null,
      clientPhoneNumber: null,

      // suggestions
      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      selected: null,

      // overlay
      latestDocClient: null,
      loadingInfiniteScroll: false,
      apiLoaded: true,
      activeFilters: false,

      // config users
      statusReferral: '',
      userData: '',
      clinicianOrClinicianViewer: false,
      isClinician: false,
      dataSpecialist: false,
      adminOrManageUsers: false,
      programClinician: '',
      isAdmin: false,
      isClinicianStaff: false,
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Clients' }
    ]
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BFormGroup,
    BTabs,
    BTab,
    BCardText,
    BFormSelect,
    BSpinner,
    BModal,

    vSelect,
    VueAutosuggest,
  },
  computed: {
    ...mapGetters(['arrayFiltradoClient']),
    ...mapState(['searchClientNameState','searchClientIDState','searchClientClinicianNameState','searchClientClientCountyState','searchClientClinicianRegionState','searchClientClinicianBoroughState','searchClientStatusState','searchClientEmailState','searchClientPhoneNumberState']),
  },
  watch: {
    clinicianName(val) {
      this.perPage = 30
      val ? this.perPage += 20 : this.perPage = 30
      this.apiLoaded = true
      // console.log(val)
      if(val) {
        if(val.value === 'All Clinicians') {
          this.clinicianName = null
          this.apiLoaded = false
          this.activeFilters = false
          // this.searchClientClinicianName('')
        } else {
          this.activeFilters = true
          val.value ? this.searchClientClinicianName(val.value) : this.searchClientClinicianName(val)
        }
      } else {
        this.apiLoaded = false
        this.activeFilters = false
        this.searchClientClinicianName('')
      }
    },
    clientName(val) {
      this.perPage = 30
      val ? this.perPage += 20 : this.perPage = 30
      if(val){
        this.activeFilters = true
        this.searchClientName(val)
      } else {
        this.apiLoaded = false
        this.activeFilters = false
        this.searchClientName('')
      }
    },
    clientCounty(val) {
      this.perPage = 30
      val ? this.perPage += 20 : this.perPage = 30
      this.apiLoaded = true
      val ? this.activeFilters = true : this.activeFilters = false
      this.searchClientClientCounty(val)
    },
    clinicianRegion(val) {
      this.perPage = 30
      val ? this.perPage += 20 : this.perPage = 30
      this.apiLoaded = true
      val ? this.activeFilters = true : this.activeFilters = false
      this.searchClientClinicianRegion(val)
    },
    borough(val) {
      if(val !== 'NYC') this.borough = null
      this.perPage = 30
      val ? this.perPage += 20 : this.perPage = 30
      this.apiLoaded = true
      val ? this.activeFilters = true : this.activeFilters = false
      this.searchClientClinicianBorough(val)
    },
    clientStatus(val) {
      this.perPage = 30
      val ? this.perPage += 20 : this.perPage = 30
      this.apiLoaded = true
      val ? this.activeFilters = true : this.activeFilters = false
      this.searchClientStatus(val)
    },
    clientID(val) {
      this.perPage = 30
      val ? this.perPage += 20 : this.perPage = 30
      this.apiLoaded = true
      val ? this.activeFilters = true : this.activeFilters = false
      val ? this.searchClientID(val.includes('#') ? val : '#'+val) : this.searchClientID('')
    },
    clientEmail(val) {
      this.perPage = 30
      val ? this.perPage += 20 : this.perPage = 30
      this.apiLoaded = true
      val ? this.activeFilters = true : this.activeFilters = false
      val ? this.searchClientEmail(val) : this.searchClientEmail('')
    },
    clientPhoneNumber(val) {
      this.perPage = 30
      val ? this.perPage += 20 : this.perPage = 30
      this.apiLoaded = true
      val ? this.activeFilters = true : this.activeFilters = false
      val ? this.searchClientPhoneNumber(val) : this.searchClientPhoneNumber('')
    },
    activeFilters(val) {
      if(val) {
        this.perPage += 20
      } else {
        this.perPage = 30
      }
      // console.log(this.perPage)
    },
    perPage(val) {
      if(this.activeFilters) this.loadingInfiniteScroll = false
    },
    arrayFiltradoClient(val) {
      this.apiLoaded = false
      this.totalRows = val.length
      // console.log(val.length)
    }
  },
  methods: {
    ...mapActions(['setSnapshotClient','searchClientName','searchClientID','searchClientClinicianName','searchClientClinicianRegion','searchClientStatus','searchClientClientCounty','searchClientClinicianBorough','searchClientEmail','searchClientPhoneNumber']),
    onRowSelected(items) {
      this.$router.push({ name: 'edit-client', params: {id: items.id} })
    },
    BtnActivateClient(payload) {
      // this.boxOne = ''
      this.$bvModal
      .msgBoxConfirm('Are you sure you want to activate?', {
        title: 'Please Confirm',
        cancelVariant: 'outline-secondary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        centered: true,
      })
      .then(value => {
        if(value) {
          db.collection('clients').doc(payload).update({
            status: 'active'
          })
          .then(() => {
            this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/update-client/relations/${payload}`)
            .then(() => console.log('relations updated'))
            .catch(err => {
              console.log(err)
            })
            
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  title: `Client activated.`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
              },
            })
          })
        }
      })
    },
    BtnDeactivateClient(payload) {
      this.$bvModal
      .msgBoxConfirm('Are you sure you want to disable?', {
        title: 'Please Confirm',
        cancelVariant: 'outline-secondary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        centered: true,
      })
      .then(value => {
        // console.log(value)
        if(value) {
          db.collection('clients').doc(payload).update({
            status: 'inactive'
          })
          .then(() => {
            this.$http.get(`https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/update-client/relations/${payload}`)
            .then(() => console.log('relations updated'))
            .catch(err => {
              console.log(err)
            })
            
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                  title: `Client deactivated.`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
              },
            })
          })
        }
      })
    },
    async getUsers() {
      const ref = db.collection('clients').where('status', '>=', 'active').orderBy('status').startAfter(this.latestDocClient || 0).limit(25)
      const data = await ref.get()
      // this.totalRows = data.docs.size
      data.docs.forEach(doc => {
        var objectClients = {}
        // var statusClient = ''
        this.clients.push({
          id: doc.id,
          clientID: '#' + doc.data().id,
          statusClient: doc.data().status,
          status: doc.data().statusLogicOnHold ? doc.data().statusLogicOnHold : doc.data().statusAct ? doc.data().statusAct : '',
          name: doc.data().firstName + ' ' + doc.data().lastName,
          email: doc.data().email,
          dateOfBirth: doc.data().dateOfBirth,
          clientClinicianName: doc.data().clientClinicianNombre ? doc.data().clientClinicianNombre : '',
          clientClientCounty: doc.data().countryOfResidence ? doc.data().countryOfResidence : '',
          // clientClinicianRegion: doc.data().clientClinicianRegion ? doc.data().clientClinicianRegion : '',
          // clientClinicianBorough: doc.data().clientClinicianBorough ? doc.data().clientClinicianBorough : '',
          clientStatus: doc.data().statusAct ? doc.data().statusAct : '',
          clRegion: doc.data().clRegion ? doc.data().clRegion : '',
          phone: doc.data().phone ? doc.data().phone : '',
          clientClinicianRegion: doc.data().clientEngagementsRegion ? doc.data().clientEngagementsRegion : '',
          clientClinicianBorough: doc.data().clientEngagementsBorough ? doc.data().clientEngagementsBorough : '',
          activitiesClinician: doc.data().activitiesClinician ? doc.data().activitiesClinician : ''
        })
        // this.clients.push(ownUser)
      })
      // console.log(this.clients.length)
      this.latestDocClient = data.docs[data.docs.length - 1]
      this.loadingInfiniteScroll = false
      // this.setSnapshotClient(this.clients)
      if(data.empty) {
        window.removeEventListener('scroll', this.handleScroll)
      }
    },
    handleScroll(event) {
      // console.log(document.documentElement.scrollTop+window.pageYOffset,document.documentElement.scrollHeight)
      if(!this.loadingInfiniteScroll) {
        let triggerHeight = document.documentElement.scrollTop + window.pageYOffset
        if(triggerHeight >= (document.documentElement.scrollHeight)-300) {
          // if(!this.activeFilters) this.getUsers()
          this.loadingInfiniteScroll = true
          
          this.perPage += 10
          this.loadingInfiniteScroll = false
        }
      }
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest.filter(item => item.value.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredDesigner = this.datasuggest.filter(item => item.value.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers.concat(filteredDesigner)
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    loadAllData() {
      // console.log(this.clients)
      this.setSnapshotClient(this.clients)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  created() {
    this.clientName = this.searchClientNameState ? this.searchClientNameState : null
    this.clientID = this.searchClientIDState ? this.searchClientIDState : null
    this.clinicianName = this.searchClientClinicianNameState ? this.searchClientClinicianNameState : null
    this.clientCounty = this.searchClientClientCountyState ? this.searchClientClientCountyState.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : null
    this.clinicianRegion = this.searchClientClinicianRegionState ? this.searchClientClinicianRegionState : null
    this.borough = this.searchClientClinicianBoroughState ? this.searchClientClinicianBoroughState : null
    this.clientStatus = this.searchClientStatusState ? this.searchClientStatusState.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : null
    this.clientEmail = this.searchClientEmailState ? this.searchClientEmailState : null
    this.clientPhoneNumber = this.searchClientPhoneNumberState ? this.searchClientPhoneNumberState : null
    this.clients = []
    this.isAdmin = false
    this.adminOrManageUsers = false
    this.isClinicianStaff = false

    const user = auth.currentUser
    if(user) {
      db.collection('usuarios').doc(user.uid).get()
      .then(userDoc => {
        if(userDoc.data().role === 'admin') {
          this.adminOrManageUsers = true
          this.isAdmin = true
        } else if(userDoc.data().role === 'staff') {
          if(userDoc.data().permissions.includes('manage users')) {
            this.adminOrManageUsers = true
          } else if(userDoc.data().permissions.includes('data specialist')) {
            this.dataSpecialist = true
          } else if(userDoc.data().permissions.includes('clinician viewer')) {
            this.clinicianOrClinicianViewer = true
          } else {
            this.clinicianOrClinicianViewer = true
          }
        } else if(userDoc.data().role === 'clinician') {
          this.isClinician = true
          this.programClinician = userDoc.data().program ? userDoc.data().program : ''
        } else if(userDoc.data().role === 'clinician-staff') {
          this.isClinicianStaff = true
        }
        
        // console.log(this.isClinician)
        if(this.isClinician) {
          db.collection('clientsActivity').where('clinician', 'array-contains', user.uid).get()
          .then(engaged => {
            // this.optionsClient = []
            var arrayClients = []
            engaged.forEach(clients => {
              db.collection('clients').doc(clients.data().client).get()
              .then(doc => {
                // docsClinicians.forEach(dataClinician => {
                this.apiLoaded = true
                // res.data.forEach(item => {
                if(arrayClients.filter(elt => elt === doc.id).length === 0) {
                  arrayClients.push(doc.id)
                  var obj = {
                    id: doc.id,
                    clientID: '#' + doc.data().id,
                    statusClient: doc.data().status,
                    status: doc.data().statusLogicOnHold ? doc.data().statusLogicOnHold : doc.data().statusAct ? doc.data().statusAct : '',
                    name: doc.data().firstName + ' ' + doc.data().lastName,
                    email: doc.data().email,
                    dateOfBirth: doc.data().dateOfBirth,
                    clientClinicianName: doc.data().clientClinicianNombre ? doc.data().clientClinicianNombre : '',
                    clientClientCounty: doc.data().countryOfResidence ? doc.data().countryOfResidence : '',
                    // clientClinicianRegion: doc.data().clientClinicianRegion ? doc.data().clientClinicianRegion : '',
                    // clientClinicianBorough: doc.data().clientClinicianBorough ? doc.data().clientClinicianBorough : '',
                    clientStatus: doc.data().statusAct ? doc.data().statusAct : '',
                    clRegion: doc.data().clRegion ? doc.data().clRegion : '',
                    phone: doc.data().phone ? doc.data().phone : '',
                    clientClinicianRegion: doc.data().clientEngagementsRegion ? doc.data().clientEngagementsRegion : '',
                    clientClinicianBorough: doc.data().clientEngagementsBorough ? doc.data().clientEngagementsBorough : '',
                    activitiesClinician: doc.data().activitiesClinician ? doc.data().activitiesClinician : ''
                  }

                  if(obj.dateOfBirth) {
                    obj.dateOfBirth1 = obj.dateOfBirth
                    var dateOfBirth = moment(obj.dateOfBirth,'MM-DD-YYYY').format('MM/DD/YYYY')
                    var formatDateOrigin = moment(dateOfBirth).format('YYYY/MM/DD HH:mm:ss')
                    obj.dateOfBirth = formatDateOrigin
                    // console.log(obj.dateOfBirth,formatDateOrigin)
                  } else {
                    obj.dateOfBirth1 = ''
                    obj.dateOfBirth = ''
                  }
                  // console.log(obj)
                  this.clients.push(obj)
                // })
                // })
                }
              })
            })
            setTimeout(() => {
              this.loadAllData()
            }, 1500)
            // console.log(this.clients)
            // this.setSnapshotClient(res.data)
          })
        } else if(this.isClinicianStaff) {
          // db.collection('clientsActivity').where('clinician', 'array-contains-any', [userDoc.data().dependency,user.uid]).get()
          db.collection('clientsActivity').where('clinician', 'array-contains', user.uid).get()
          .then(engaged => {
            // this.optionsClient = []
            var arrayClients = []
            engaged.forEach(clients => {
              db.collection('clients').doc(clients.data().client).get()
              .then(doc => {
                // docsClinicians.forEach(dataClinician => {
                this.apiLoaded = true
                // res.data.forEach(item => {
                if(arrayClients.filter(elt => elt === doc.id).length === 0) {
                  arrayClients.push(doc.id)
                  var obj = {
                    id: doc.id,
                    clientID: '#' + doc.data().id,
                    statusClient: doc.data().status,
                    status: doc.data().statusLogicOnHold ? doc.data().statusLogicOnHold : doc.data().statusAct ? doc.data().statusAct : '',
                    name: doc.data().firstName + ' ' + doc.data().lastName,
                    email: doc.data().email,
                    dateOfBirth: doc.data().dateOfBirth,
                    clientClinicianName: doc.data().clientClinicianNombre ? doc.data().clientClinicianNombre : '',
                    clientClientCounty: doc.data().countryOfResidence ? doc.data().countryOfResidence : '',
                    // clientClinicianRegion: doc.data().clientClinicianRegion ? doc.data().clientClinicianRegion : '',
                    // clientClinicianBorough: doc.data().clientClinicianBorough ? doc.data().clientClinicianBorough : '',
                    clientStatus: doc.data().statusAct ? doc.data().statusAct : '',
                    clRegion: doc.data().clRegion ? doc.data().clRegion : '',
                    phone: doc.data().phone ? doc.data().phone : '',
                    clientClinicianRegion: doc.data().clientEngagementsRegion ? doc.data().clientEngagementsRegion : '',
                    clientClinicianBorough: doc.data().clientEngagementsBorough ? doc.data().clientEngagementsBorough : '',
                    activitiesClinician: doc.data().activitiesClinician ? doc.data().activitiesClinician : ''
                  }

                  if(obj.dateOfBirth) {
                    obj.dateOfBirth1 = obj.dateOfBirth
                    var dateOfBirth = moment(obj.dateOfBirth,'MM-DD-YYYY').format('MM/DD/YYYY')
                    var formatDateOrigin = moment(dateOfBirth).format('YYYY/MM/DD HH:mm:ss')
                    obj.dateOfBirth = formatDateOrigin
                    // console.log(obj.dateOfBirth,formatDateOrigin)
                  } else {
                    obj.dateOfBirth1 = ''
                    obj.dateOfBirth = ''
                  }
                  // console.log(obj)
                  if(this.clients.filter(ele => ele.id === doc.id).length === 0) {
                    this.clients.push(obj)
                  }
                // })
                // })
                }
              })
            })
            setTimeout(() => {
              this.loadAllData()
            }, 1500)
            // console.log(this.clients)
            // this.setSnapshotClient(res.data)
          })
        } else {
          db.collection('clients').doc('counter').get()
          .then(res => {
            // console.log(res.data().active)
            this.totalRows = res.data().active
          })

          this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active/clients')
          .then(res => {
            // console.log(res.data)
            this.apiLoaded = true
            res.data.forEach(item => {
              if(item.dateOfBirth) {
                item.dateOfBirth1 = item.dateOfBirth
                var dateOfBirth = moment(item.dateOfBirth,'MM-DD-YYYY').format('MM/DD/YYYY')
                var formatDateOrigin = moment(dateOfBirth).format('YYYY/MM/DD HH:mm:ss')
                item.dateOfBirth = formatDateOrigin
                // console.log(item.dateOfBirth,formatDateOrigin)
              } else {
                item.dateOfBirth1 = ''
                item.dateOfBirth = ''
              }
            })

            this.setSnapshotClient(res.data)
            // window.removeEventListener('scroll', this.handleScroll)
          })
        }

        // status
        this.statusReferral = userDoc.data().statusReferral ? userDoc.data().statusReferral : ''
        
        this.userData = userDoc.data()
      })
    }

    this.optionsClinicianName = []
    this.optionsClinicianName = [{id: 0, uid: 0, value: 'All Clinicians', full: 'All Clinicians'}]

    this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/filter/clinicians')
    .then(res => {
      res.data.forEach(ele => {
        this.optionsClinicianName.push(ele)
      })
      // this.optionsClinicianName = res.data
      // console.log(res.data)
    })

    db.collection('taxonomies').doc('client').get()
    .then(docTaxonomia => {
     this.optionsClientCounty = [{value: null, text: 'Any County'}]
      docTaxonomia.data().countryOfResidence.forEach(item => {
        this.optionsClientCounty.push({value: item, text: item})
      })
    })

    db.collection('taxonomies').doc('clinician').get()
    .then(docTaxonomia => {
      var arrayRegion = [{value: null, text: 'Any Region'}]
      docTaxonomia.data().region.forEach(item => {
        arrayRegion.push({value: item, text: item})
      })
      this.optionsClinicianRegion = arrayRegion
    })

    // clients update array
    // db.collection('clients').get()
    // .then(docsClients => {
    //   docsClients.forEach(docClient => {
    //     if(!docClient.data().activitiesClinician) {
    //       db.collection('clients').doc(docClient.id).update({
    //         activitiesClinician: []
    //       })
    //       .then(() => console.log('updated'))
    //     } else {
    //       console.log('already field')
    //     }
    //   })
    // })
    
    // activity update clinician info
    // db.collection('clients').get()
    // .then(docsClients => {
    //   docsClients.forEach(docClient => {
    //     if(docClient.data().activitiesClinician.length === 0) {
    //       db.collection('clientsActivity').where('client', '==', docClient.id).get()
    //       .then(docsActivity => {
    //         if(docsActivity.size) {
    //           docsActivity.forEach(dataCA => {
    //             if(dataCA.data().clinician && dataCA.data().clinician.length >= 1) {
    //               db.collection('usuarios').where('uid', 'in', dataCA.data().clinician).get()
    //               .then(docsUsers => {
    //                 if(docsUsers.size >= 1) {
    //                   var arrayClinicians = []
    //                   docsUsers.forEach(docUser => {
    //                     var obj = {
    //                       id: docUser.id,
    //                       codigo: '#' + docUser.data().codigo,
    //                       name: docUser.data().agency + ' · ' + docUser.data().firstName + ' ' + docUser.data().lastName + ' (#' + docUser.data().codigo + ')',
    //                       status: docUser.data().status ? docUser.data().status : 'inactive',
    //                       region: docUser.data().region ? docUser.data().region : '',
    //                       borough: docUser.data().borough ? docUser.data().borough : ''
    //                     }
    //                     arrayClinicians.push(obj)
    //                   })

    //                   // object clinicians info
    //                   var objCA = {
    //                     id: dataCA.id,
    //                     statusActivity: dataCA.data().activityStatusLogical ? dataCA.data().activityStatusLogical : dataCA.data().activityStatus ? dataCA.data().activityStatus : '',
    //                     clinicians: arrayClinicians
    //                   }

    //                   db.collection('clients').doc(docClient.id).update({
    //                     activitiesClinician: firebase.firestore.FieldValue.arrayUnion(objCA)
    //                   })
    //                   .then(() => console.log('updated'))
    //                 } else {
    //                   console.log('no clinicians')
    //                 }
    //               })
    //             }
    //           })
    //         } else {
    //           console.log('no activities client')
    //         }
    //       })
    //     }
    //   })
    // })
    
    // activity update client status
    // db.collection('clientsActivity').get()
    // .then(getDocs => {
    //   getDocs.forEach(dataCA => {
    //     if(dataCA.id !== 'counter') {
    //       if(dataCA.data().client && !dataCA.data().clientStatus) {
    //         db.collection('clients').doc(dataCA.data().client).get()
    //         .then(docClient => {
    //           // update clientStatus client
    //           db.collection('clientsActivity').doc(dataCA.id).update({
    //             clientStatus: docClient.data().status ? docClient.data().status : 'active'
    //           })
    //           .then(() => console.log('first'))
    //         })
    //       } else {
    //         console.log('second')
    //       }
    //     }
    //   })
    // })

    // activity update client information
    // db.collection('clientsActivity').get()
    // .then(getDocs => {
    //   getDocs.forEach(dataCA => {
    //     if(dataCA.id !== 'counter') {
    //       if(dataCA.data().client) {
    //         if(dataCA.data().statusLogicOnHold === undefined) {
    //           var statusLogical = ''
    //           var statusLogicOnHold = ''
    //           if(dataCA.data().dateOfAdmission && dataCA.data().removeRecordDischargeData) {
    //             statusLogical = 'Admitted'
    //             statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
    //           } else if(dataCA.data().dateOfAdmission && !dataCA.data().dateOfDischarge) {
    //             statusLogical = 'Admitted'
    //             statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Admitted (On Hold)' : 'Admitted'
    //           } else if(dataCA.data().dateOfDischarge) {
    //             statusLogical = 'Discharged'
    //             statusLogicOnHold = dataCA.data().activityStatus === 'On Hold' ? 'Discharged (On Hold)' : 'Discharged'
    //           } else {
    //             statusLogical = dataCA.data().activityStatus
    //             statusLogicOnHold = dataCA.data().activityStatus
    //           }

    //           // update statusAct client
    //           db.collection('clients').doc(dataCA.data().client).update({
    //             statusAct: statusLogical
    //             // statusLogicOnHold: statusLogicOnHold
    //           })
    //           .then(() => console.log('first'))
    //         } else {
    //           console.log('second')
    //         }
    //       }
    //     }
    //   })
    // })
  }
}
</script>

<style>
.actionsCol {
  /* display: grid; */
  width: 150px;
}
th {
  vertical-align: middle !important;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>